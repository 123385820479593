import { ICategory } from "src/components/side-menu/sub-elements/Category";
const menu: ICategory[] = [
  {
    name: "Components & equipment",
    id: "components-equipment",
    subcategories: [
      {
        name: "Pitch",
        id: "pitch",
        elements: [
          {
            name: "Pitch",
            id: "the-pitch",
            link: "/components-equipment/the-pitch/",
            tag: ["pitch"],
          },
          {
            name: "PENALTY AREA",
            id: "penalty-area",
            link: "/components-equipment/penalty-area/",
            tag: ["penalty", "area"],
          },
          {
            name: "GOAL AREA",
            id: "goal-area",
            link: "/components-equipment/goal-area/",
            tag: ["goal", "area"],
          },
          {
            name: "CORNER AREA AND FLAGS",
            id: "corner-area-and-flags",
            link: "/components-equipment/corner-area-and-flags/",
            tag: ["corner", "area", "flags"],
          },
          {
            name: "Technical area",
            id: "technical-area",
            link: `/components-equipment/technical-area/`,
            tag: ["dug out"],
          },
          {
            name: "REFEREE REVIEW AREA",
            id: "referee-review-area",
            link: `/components-equipment/referee-review-area/`,
            tag: ["referee", "area"],
          },
        ],
      },
      {
        name: "Goals",
        id: "Goals",
        link: `/components-equipment/goals/`,
        tag: ["goal"],
      },
      {
        name: "Ball",
        id: "Ball",
        link: `/components-equipment/ball/`,
        tag: ["football"],
      },
    ],
  },
  {
    name: "participants",
    id: "participants",
    subcategories: [
      {
        name: "players",
        id: "id-players",
        elements: [
          {
            name: "players & SUBSTITUTES",
            id: "players",
            link: "/participants/players/",
            tag: ["player", "captains", "goalkeeper"],
          },
          {
            name: "goalkeepers",
            id: "goalkeepers",
            link: "/participants/goalkeepers/",
            tag: ["player", "captains", "goalkeeper"],
          },
          {
            name: "captains",
            id: "captains",
            link: "/participants/captains/",
            tag: ["captains"],
          },
          {
            name: "Players' Kit",
            id: "Player-kit",
            link: `/components-equipment/players-kit/`,
            tag: ["equipment", "strip", "shirt"],
          },
        ],
      },
      {
        name: "Coaches & other team officials",
        id: "coaches",
        link: "/participants/coaches-other-team-officials/",
        tag: ["manager", "managers"],
      },
      {
        name: "REFEREE & OTHER match OFFICIALS",
        id: "id-referee",
        elements: [
          {
            name: "Referee",
            id: "referee",
            link: "/participants/referee/",
            tag: ["match official", "assistant referee"],
          },
          {
            name: "ASSISTANT REFEREES",
            id: "assistant-referees",
            link: "/participants/assistant-referees/",
            tag: ["assistant", "referee"],
          },
          {
            name: "FOURTH OFFICIAL",
            id: "fourth-official",
            link: "/participants/fourth-official/",
            tag: ["fourth", "official"],
          },
          {
            name: "VAR (PEOPLE)",
            id: "video-assistant-referees",
            link: "/participants/video-assistant-referees/",
            tag: ["video", "assistant", "referees"],
          },
        ],
      },
    ],
  },
  {
    name: "Game events",
    id: "game-events",
    subcategories: [
      {
        name: "Playing times",
        id: "playing-times",
        link: `/game-events/playing-times/`,
        tag: [
          "halves",
          "45 minutes",
          "90 minutes",
          "half-time",
          "half-time break",
        ],
      },
      {
        name: "Kick-off",
        id: "kick-off",
        link: `/game-events/kick-off/`,
      },
      {
        name: "Ball in & ball out of play",
        id: "Ball-in-&-out-of-play",
        link: "/game-events/ball-in-out-of-play/",
        tag: ["dead ball"],
      },
      {
        name: "Corner kick",
        id: "Corner-kick",
        link: "/game-events/corner-kick/",
        tag: ["corner"],
      },
      {
        name: "dropped ball",
        id: "dropped-ball",
        link: "/offences-sanctions/dropped-ball/",
        tag: ["bounce"],
      },
      {
        name: "goal kick",
        id: "goal-kick",
        link: "/game-events/goal-kick/",
        tag: ["kicking"],
      },
      {
        name: "throw-in",
        id: "throw-in",
        link: "/game-events/throw-in/",
        tag: ["thrower"],
      },
      {
        name: "Scoring a goal",
        id: "Scoring-a-goal",
        link: `/game-events/scoring-a-goal/`,
        tag: ["goal"],
      },
      {
        name: "penalties",
        id: "penalty-shoot-out",
        link: "/game-events/penalty-shoot-out/",
        tag: ["kicks from the penalty mark", "penalties"],
      },
      {
        name: "winning team",
        id: "winning-team",
        link: "/game-events/winning-team/",
        tag: ["winner"],
      },
      {
        name: "Injuries",
        id: "injuries",
        link: "/game-events/injuries/",
        tag: ["injury time"],
      },
      {
        name: "SUBSTITUTIONS",
        id: "substitutes",
        link: "/game-events/substitutes/",
        tag: ["reserve", "subs", "substitutes", "substitute"],
      },
      {
        name: "drinks & cooling breaks",
        id: "drinks-cooling-breaks",
        link: "/game-events/drinks-cooling-breaks/",
        tag: ["cool down", "rehydrate", "drinks"],
      },
      {
        name: "advantage",
        id: "advantage",
        link: "/game-events/advantage/",
        tag: [""],
      },
      {
        name: "playing distance",
        id: "playing distance",
        link: "/game-events/playing-distance/",
        tag: ["distance"],
      },
    ],
  },
  {
    name: "offences & sanctions",
    id: "offences-sanctions",
    subcategories: [
      {
        name: "fouls",
        id: "fouls",
        link: "/offences-sanctions/fouls/",
        tag: ["foul"],
      },
      {
        name: "handball",
        id: "handball",
        link: "/offences-sanctions/handball/",
        tag: ["hands", "hand"],
      },
      {
        name: "offside",
        id: "offside",
        link: "/offences-sanctions/offside/",
        tag: ["off"],
      },
      {
        name: "other offences",
        id: "other-offences",
        elements: [
          {
            name: "arguing",
            id: "arguing",
            link: "/offences-sanctions/arguing/",
            tag: ["dissent", "protests", "complaint", "protest"],
          },
          {
            name: "feinting",
            id: "feinting",
            link: "/offences-sanctions/feinting/",
            tag: ["dummying"],
          },
          {
            name: "obstruction",
            id: "obstruction",
            link: "/offences-sanctions/obstruction/",
            tag: ["impeding"],
          },
          {
            name: "simulation",
            id: "id-simulation",
            link: "/offences-sanctions/simulation/",
            tag: ["diving"],
          },
          {
            name: "swearing",
            id: "swearing",
            link: "/offences-sanctions/swearing/",
            tag: ["offensive", "insulting", "abusive"],
          },
          {
            name: "other offences",
            id: "other-offences",
            link: "/offences-sanctions/other-offences/",
            tag: [
              "offside offence",
              "verbal offence",
              "simulation",
              "diving",
              "unfair behaviour",
            ],
          },
        ],
      },
      {
        name: "free kicks",
        id: "free-kicks",
        link: "/offences-sanctions/free-kicks/",
        tag: ["direct free kick", "indirect free kick"],
      },
      {
        name: "penalty kicks",
        id: "penalty-kick",
        link: "/offences-sanctions/penalty-kick/",
        tag: [],
      },
      {
        name: "cards",
        id: "cards",
        elements: [
          {
            name: "Yellow Cards",
            id: "card-yellow",
            link: "/offences-sanctions/yellow-card/",
            tag: ["send off", "dismiss", "sending off"],
          },
          {
            name: "red cards",
            id: "red-card",
            link: "/offences-sanctions/red-card/",
            tag: ["send off", "dismiss", "sending off"],
          },
        ],
      },
      {
        name: "INTERFERENCE ON THE PITCH ",
        id: "id-interference",
        elements: [          {
          name: "SUBSTITUTES OR TEAM OFFICIALS",
          id: "substitutes-or-team-officials",
          link: "/game-events/substitutes-or-team-officials/",
          tag: ["reserve", "subs", "substitutes", "substitute"],
        },
          {
            name: "extra person or object",
            id: "extra-person-or-object",
            link: "/game-events/extra-person-or-object/",
            tag: ["outside agent"],
          },
        ],
      },
      {
        name: "temporary dismissals",
        id: "temporary-dismissals",
        link: "/offences-sanctions/temporary-dismissals/",
        tag: ["sins bins"],
      },
    ],
  },
  {
    name: "technology",
    id: "technology",
    subcategories: [
      {
        name: "goal line technology",
        id: "goal-line-technology",
        link: "/technology/goal-line-technology/",
        tag: ["glt"],
      },
      {
        name: "VAR (CONCEPT)",
        id: "var",
        link: "/technology/var/",
        tag: ["video assistant referee", "var"],
      },
      {
        name: "SEMI-AUTOMATED OFFSIDE TECHNOLOGY",
        id: "semi-automated-offside-technology",
        link: "/technology/semi-automated-offside-technology/",
        tag: [""],
      },
    ],
  },
  {
    name: "about ",
    id: "about",
    subcategories: [
      {
        name: "football rules",
        id: "simplified-football-rules",
        link: "/about/simplified-football-rules/",
        tag: ["rules"],
      },
      {
        name: "laws of the game",
        id: "laws-of-the-game",
        link: "/about/laws-of-the-game/",
        tag: ["laws"],
      },
      {
        name: "competition rules",
        id: "additional-competition-rules",
        link: "/about/additional-competition-rules/",
        tag: ["rules"],
      },
    ],
  },
  {
    name: "pdf downloads",
    id: "pdf-downloads",
    subcategories: [
      {
        name: "pdf by category",
        id: "pdf-by-category",
        link: '#',
        tag: ["pdf"],
      },
      {
        name: "pdf A-Z",
        id: "pdf-a-z",
        link: '#',
        tag: ["pdf"],
      },
    ],
  },
];

const useMenuStructure = (str: string): ICategory[] => {
  if (str) {
    let data = [];
    let elementData = [];
    let finalData = [];
    let newData = [];
    for (let i = 0; i < menu.length; i++) {
      for (let j = 0; j < menu[i].subcategories.length; j++) {
        if (
          menu[i].subcategories[j].name
            .toLowerCase()
            .includes(str.toLowerCase())
        ) {
          const da = data.find((x) => x.name === menu[i].name);
          if (da) {
            da.subcategories = [
              ...da.subcategories,
              {
                name: menu[i].subcategories[j].name,
                id: menu[i].subcategories[j].id,
                link: menu[i].subcategories[j].link,
              },
            ];
            // da.subcategories = a;
          } else {
            data.push({
              name: menu[i].name,
              id: menu[i].id,
              subcategories: [
                {
                  name: menu[i].subcategories[j].name,
                  id: menu[i].subcategories[j].id,
                  link: menu[i].subcategories[j].link,
                },
              ],
            });
          }
        }

        if (
          menu[i].subcategories[j]?.tag?.find((x) => x === str.toLowerCase())
        ) {
          const da = data.find((x) => x.name === menu[i].name);
          if (da) {
            const a = [
              ...da.subcategories,
              {
                name: menu[i].subcategories[j].name,
                id: menu[i].subcategories[j].id,
                link: menu[i].subcategories[j].link,
              },
            ];
            //da.subcategories = a;
          } else {
            data.push({
              name: menu[i].name,
              id: menu[i].id,
              subcategories: [
                {
                  name: menu[i].subcategories[j].name,
                  id: menu[i].subcategories[j].id,
                  link: menu[i].subcategories[j].link,
                },
              ],
            });
          }
        }

        if (menu[i].subcategories[j].elements) {
          if (menu[i].subcategories[j].elements) {
            for (let z = 0; z < menu[i].subcategories[j].elements.length; z++) {
              if (
                menu[i].subcategories[j].elements[z].name
                  .toLowerCase()
                  .includes(str.toLowerCase())
              ) {
                const elementDatafind = elementData.find(
                  (x) => x.name === menu[i].name
                );
                if (elementDatafind) {
                  const a = [
                    ...elementDatafind.subcategories[0].elements,
                    {
                      name: menu[i].subcategories[j].elements[z].name,
                      id: menu[i].subcategories[j].elements[z].id,
                      link: menu[i].subcategories[j].elements[z].link,
                    },
                  ];
                  elementDatafind.subcategories[0].elements = a;
                } else {
                  elementData.push({
                    name: menu[i].name,
                    id: menu[i].id,
                    subcategories: [
                      {
                        name: menu[i].subcategories[j].name,
                        id: menu[i].subcategories[j].id,
                        elements: [
                          {
                            name: menu[i].subcategories[j].elements[z].name,
                            id: menu[i].subcategories[j].elements[z].id,
                            link: menu[i].subcategories[j].elements[z].link,
                          },
                        ],
                      },
                    ],
                  });
                }
              }
            }
          }
          //check tags
          if (menu[i].subcategories[j].elements) {
            for (let z = 0; z < menu[i].subcategories[j].elements.length; z++) {
              if (
                menu[i].subcategories[j].elements[z].tag.find(
                  (x) => x === str.toLowerCase()
                )
              ) {
                const elementDatafind = elementData.find(
                  (x) => x.name === menu[i].name
                );
                const isCategoryExists = elementData.find(
                  (x) => x.subcategories?.elements
                );

                if (
                  isCategoryExists &&
                  isCategoryExists.subcategories.elements &&
                  Array.isArray(isCategoryExists.subcategories.elements)
                ) {
                } else {
                  if (isCategoryExists) {
                    isCategoryExists.subcategories.elements = [
                      isCategoryExists.subcategories.elements,
                    ];
                  }
                }

                //check if subcategory is an array
                if (elementDatafind) {
                  const a = {
                    name: menu[i].subcategories[j].elements[z].name,
                    id: menu[i].subcategories[j].elements[z].id,
                    link: menu[i].subcategories[j].elements[z].link,
                  };

                  const exists = elementDatafind.subcategories[0].elements.find(
                    (x) => x.name === a.name
                  );
                  if (!exists) {
                    elementDatafind.subcategories[0].elements = [
                      ...elementDatafind.subcategories[0].elements,
                      a,
                    ];
                  }
                  // elementDatafind.subcategories[0].elements.slice(1);
                } else {
                  elementData.push({
                    name: menu[i].name,
                    id: menu[i].id,
                    subcategories: [
                      {
                        name: menu[i].subcategories[j].name,
                        id: menu[i].subcategories[j].id,

                        elements: [
                          {
                            name: menu[i].subcategories[j].elements[z].name,
                            id: menu[i].subcategories[j].elements[z].id,
                            link: menu[i].subcategories[j].elements[z].link,
                          },
                        ],
                      },
                    ],
                  });
                }
              }
            }
          }
        }
      }
    }
    //loop through final array
    for (let z = 0; z < elementData.length; z++) {
      for (let q = 0; q < data.length; q++) {
        if (elementData[z].name === data[q].name) {
          data.splice(q, 1);
        }
      }
    }
    finalData = data.concat(elementData);

    return finalData;
  } else {
    return menu;
  }
};

export function useSortMenuStructure(menuResult: ICategory[]) {
  let sortedData: ICategory[] = [];
  let orderedData: ICategory[] = [];
  for (let iz = 0; iz < menuResult.length; iz++) {
    menuResult[iz].subcategories.map((item) => {
      if (item?.elements) {
        item?.elements.map((e, index) => {
          const getIndexKey = e.name[0].toUpperCase();
          //check if key already exists
          const isKeyExists = sortedData.find((x) => x.name === getIndexKey);

          if (e.id.includes("pdf"))
          {
          } else if (isKeyExists && e.link) {
            isKeyExists.subcategories = [
              ...isKeyExists.subcategories,
              {
                name: e.name,
                id: e.id,
                link: e.link,
              },
            ];
          } else {
            if (e.link) {
              sortedData.push({
                name: getIndexKey,
                id: getIndexKey,
                subcategories: [
                  {
                    name: e.name,
                    id: e.id,
                    link: e.link,
                  },
                ],
              });
            }
          }
        });
      } else {
        const getIndexKey = item.name[0].toUpperCase();
        const isKeyExists = sortedData.find((x) => x.name === getIndexKey);
        if (item.id.includes("pdf"))
        {
        } else if (isKeyExists && item.link) {
          isKeyExists.subcategories = [
            ...isKeyExists.subcategories,
            {
              name: item.name,
              id: item.id,
              link: item.link,
            },
          ];
        } else {
          if (item.link) {
            sortedData.push({
              name: getIndexKey,
              id: getIndexKey,
              subcategories: [
                {
                  name: item.name,
                  id: item.id,
                  link: item.link,
                },
              ],
            });
          }
        }
      }
    });
  }

  for (let i = 0; i < sortedData.length; i++) {
    orderedData.push({
      name: sortedData[i].name,
      id: sortedData[i].id,
      subcategories: sortedData[i].subcategories.sort((a, b) => {
        const fb = a.name.toLowerCase();
        const fc = b.name.toLowerCase();
        if (fb < fc) {
          return -1;
        }
        if (fb > fc) {
          return 1;
        }
        return 0;
      }),
    });
  }

  const _sortedData = orderedData.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return _sortedData;
}

export function searchFilteredMenu(result: ICategory[], searchString: string) {
  let response: ICategory[] = [];
  const filteredList = useSortMenuStructure(menu);
  if (result.length > 0) {
    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < result[i].subcategories.length; j++) {
        if (
          result[i].subcategories[j].name
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          result[i].subcategories[j]?.tag.includes(searchString.toLowerCase())
        ) {
          //check if name exists
          const isNameExists = response.find((x) => x.name === result[i].name);
          if (isNameExists) {
            isNameExists.subcategories = [
              ...isNameExists.subcategories,
              {
                name: result[i].subcategories[j].name,
                id: result[i].subcategories[j].id,
                link: result[i].subcategories[j].link,
              },
            ];
          } else {
            response.push({
              name: result[i].name,
              id: result[i].id,
              subcategories: [
                {
                  name: result[i].subcategories[j].name,
                  id: result[i].subcategories[j].id,
                  link: result[i].subcategories[j].link,
                },
              ],
            });
          }
        }
      }
    }
    const _sortedData = response.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return _sortedData;
  }
}

export function searchByActive(str: string) {
  let data = [];
  let elementData = [];
  let finalData = [];
  for (let i = 0; i < menu.length; i++) {
    for (let j = 0; j < menu[i].subcategories.length; j++) {
      if (menu[i].subcategories[j].link?.toLowerCase() === str.toLowerCase()) {
        const da = data.find((x) => x.name === menu[i].name);
        if (da) {
          const a = {
            name: menu[i].subcategories[j].name,
            id: menu[i].subcategories[j].id,
            link: menu[i].subcategories[j].link,
            isActive: true,
          };
          da.subcategories = [...da.subcategories, a];
        } else {
          data.push({
            name: menu[i].name,
            id: menu[i].id,
            isActive: true,
            subcategories: [
              {
                name: menu[i].subcategories[j].name,
                id: menu[i].subcategories[j].id,
                link: menu[i].subcategories[j].link,
                isActive: true,
              },
            ],
          });
        }
      } else {
        const da = data.find((x) => x.name === menu[i].name);
        if (da) {
          const a = {
            name: menu[i].subcategories[j].name,
            id: menu[i].subcategories[j].id,
            link: menu[i].subcategories[j].link,
            isActive: false,
          };
          da.subcategories = [...da.subcategories, a];
        } else {
          data.push({
            name: menu[i].name,
            id: menu[i].id,
            isActive: false,
            subcategories: [
              {
                name: menu[i].subcategories[j].name,
                id: menu[i].subcategories[j].id,
                link: menu[i].subcategories[j].link,
                isActive: false,
              },
            ],
          });
        }
      }

      if (menu[i].subcategories[j].elements) {
        for (let z = 0; z < menu[i].subcategories[j].elements.length; z++) {
          if (
            menu[i].subcategories[j].elements[z].link.toLowerCase() ===
            str.toLowerCase()
          ) {
            const elementDatafind = elementData.find(
              (x) => x.name === menu[i].name
            );
            if (elementDatafind) {
              const ba = {
                name: menu[i].subcategories[j].name,
                id: menu[i].subcategories[j].id,
                link: menu[i].subcategories[j].link,
                isActive: true,
              };
              if (elementDatafind.subcategories.elements) {
                elementDatafind.subcategories.elements = [
                  ...elementDatafind.subcategories.elements,
                  ba,
                ];
              }
            } else {
              elementData.push({
                name: menu[i].name,
                id: menu[i].id,
                isActive: true,
                subcategories: [
                  {
                    name: menu[i].subcategories[j].name,
                    id: menu[i].subcategories[j].id,
                    isActive: true,
                    elements: [
                      {
                        name: menu[i].subcategories[j].elements[z].name,
                        id: menu[i].subcategories[j].elements[z].id,
                        link: menu[i].subcategories[j].elements[z].id,
                        isActive: true,
                      },
                    ],
                  },
                ],
              });
            }
          } else {
            const elementDatafind = elementData.find(
              (x) => x.name === menu[i].name
            );
            if (elementDatafind) {
              const ba = {
                name: menu[i].subcategories[j].name,
                id: menu[i].subcategories[j].id,
                link: menu[i].subcategories[j].link,
                isActive: true,
              };
              if (elementDatafind.subcategories.elements) {
                elementDatafind.subcategories.elements = [
                  ...elementDatafind.subcategories.elements,
                  ba,
                ];
              }
            } else {
              elementData.push({
                name: menu[i].name,
                id: menu[i].id,
                isActive: false,
                subcategories: [
                  {
                    name: menu[i].subcategories[j].name,
                    id: menu[i].subcategories[j].id,
                    isActive: false,
                    elements: [
                      {
                        name: menu[i].subcategories[j].elements[z].name,
                        id: menu[i].subcategories[j].elements[z].id,
                        link: menu[i].subcategories[j].elements[z].id,
                        isActive: false,
                      },
                    ],
                  },
                ],
              });
            }
          }
        }
      }
    }
  }
  //loop through final array
  for (let z = 0; z < elementData.length; z++) {
    for (let q = 0; q < data.length; q++) {
      if (elementData[z].name === data[q].name) {
        data.splice(q, 1);
      }
    }
  }
  finalData = data.concat(elementData);

  for (let kk = 0; kk < finalData.length; kk++) {
    if (!Array.isArray(finalData[kk].subcategories)) {
      finalData[kk].subcategories = [finalData[kk].subcategories];
    }
  }
  return finalData;
}

export function searchById(str?: string) {
  for (let i = 0; i < menu.length; i++) {
    for (let j = 0; j < menu[i].subcategories.length; j++) {
      if (menu[i].subcategories[j].link === str) {
        const result = {
          name: menu[i].name,
          id: menu[i].id,
          subcategory: menu[i].subcategories[j],
        };
        return result;
      }

      if (menu[i].subcategories[j].elements) {
        for (let k = 0; k < menu[i].subcategories[j].elements.length; k++) {
          if (menu[i]?.subcategories[j]?.elements[k]?.link === str) {
            const result = {
              name: menu[i].name,
              id: menu[i].id,
              subcategory: menu[i].subcategories[j],
            };
            return result;
          }
        }
      }
    }
  }
}
export default useMenuStructure;
